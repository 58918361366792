/*timeline2*/


/******************* Timeline Demo - 4 *****************/

.main-timeline2 {
  overflow: hidden;
  position: relative
}

.main-timeline2:before {
  content: "";
  width: 5px;
  height: 100%;
  background: #333;
  position: absolute;
  top: 70px;
  left: 50%;
  transform: translateX(-50%)
}

.main-timeline2 .timeline-content:before,
.main-timeline2 .timeline:before {
  top: 50%;
  transform: translateY(-50%);
  content: ""
}

.main-timeline2 .timeline {
  width: 50%;
  padding-left: 100px;
  float: right;
  position: relative
}

.main-timeline2 .timeline:before {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #fff;
  border: 5px solid #333;
  position: absolute;
  left: -10px
}

.main-timeline2 .timeline-content {
  display: block;
  padding-left: 150px;
  position: relative
}

.main-timeline2 .timeline-content:before {
  width: 90px;
  height: 10px;
  border-top: 7px dotted #333;
  position: absolute;
  left: -92px
}

.main-timeline2 .year {
  display: inline-block;
  width: 120px;
  height: 120px;
  line-height: 100px;
  border-radius: 50%;
  border: 10px solid $primary-color;
  font-size: 30px;
  color: $primary-color;
  text-align: center;
  box-shadow: inset 0 0 10px rgba(0, 0, 0, .4);
  position: absolute;
  top: 0;
  left: 0
}

.main-timeline2 .year:before {
  content: "";
  border-left: 20px solid $primary-color;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  position: absolute;
  bottom: -13px;
  right: 0;
  transform: rotate(45deg)
}

.main-timeline2 .inner-content {
  padding: 20px 0
}

.main-timeline2 .title {
  font-size: 24px;
  color: $primary-color;
  text-transform: uppercase;
  margin: 0 0 5px
}

.main-timeline2 .description {
  font-size: 14px;
  color: #6f6f6f;
  margin: 0 0 5px
}

.main-timeline2 .timeline:nth-child(2n) {
  padding: 0 100px 0 0
}

.main-timeline2 .timeline:nth-child(2n) .timeline-content:before,
.main-timeline2 .timeline:nth-child(2n) .year,
.main-timeline2 .timeline:nth-child(2n):before {
  left: auto;
  right: -10px
}

.main-timeline2 .timeline:nth-child(2n) .timeline-content {
  padding: 0 150px 0 0
}

.main-timeline2 .timeline:nth-child(2n) .timeline-content:before {
  right: -92px
}

.main-timeline2 .timeline:nth-child(2n) .year {
  right: 0
}

.main-timeline2 .timeline:nth-child(2n) .year:before {
  right: auto;
  left: 0;
  border-left: none;
  border-right: 20px solid $primary-color;
  transform: rotate(-45deg)
}

.main-timeline2 .timeline:nth-child(2) {
  margin-top: 110px
}

.main-timeline2 .timeline:nth-child(odd) {
  margin: -100px 0 0
}

.main-timeline2 .timeline:nth-child(even) {
  margin-bottom: 80px
}

.main-timeline2 .timeline:first-child,
.main-timeline2 .timeline:last-child:nth-child(even) {
  margin: 0
}

.main-timeline2 .timeline:nth-child(2n) .year {
  border-color: $primary-color;
  color: $primary-color
}

.main-timeline2 .timeline:nth-child(2) .year:before {
  border-right-color: $primary-color
}

.main-timeline2 .timeline:nth-child(2n) .title {
  color: $primary-color
}

.main-timeline2 .timeline:nth-child(3n) .year {
  border-color: $primary-color;
  color: $primary-color
}

.main-timeline2 .timeline:nth-child(3) .year:before {
  border-left-color: $primary-color
}

.main-timeline2 .timeline:nth-child(3n) .title {
  color: $primary-color
}

.main-timeline2 .timeline:nth-child(4n) .year {
  border-color: $primary-color;
  color: $primary-color
}

.main-timeline2 .timeline:nth-child(4) .year:before {
  border-right-color: $primary-color
}

.main-timeline2 .timeline:nth-child(4n) .title {
  color: $primary-color
}

@media only screen and (max-width:1200px) {
  .main-timeline2 .year {
    top: 50%;
    transform: translateY(-50%)
  }
}

@media only screen and (max-width:990px) {
  .main-timeline2 .timeline {
    padding-left: 75px
  }

  .main-timeline2 .timeline:nth-child(2n) {
    padding: 0 75px 0 0
  }

  .main-timeline2 .timeline-content {
    padding-left: 130px
  }

  .main-timeline2 .timeline:nth-child(2n) .timeline-content {
    padding: 0 130px 0 0
  }

  .main-timeline2 .timeline-content:before {
    width: 68px;
    left: -68px
  }

  .main-timeline2 .timeline:nth-child(2n) .timeline-content:before {
    right: -68px
  }
}

@media only screen and (max-width:767px) {
  .main-timeline2 {
    overflow: visible
  }

  .main-timeline2:before {
    height: 100%;
    top: 0;
    left: 0;
    transform: translateX(0)
  }

  .main-timeline2 .timeline:before,
  .main-timeline2 .timeline:nth-child(2n):before {
    top: 60px;
    left: -9px;
    transform: translateX(0)
  }

  .main-timeline2 .timeline,
  .main-timeline2 .timeline:nth-child(even),
  .main-timeline2 .timeline:nth-child(odd) {
    width: 100%;
    float: none;
    text-align: center;
    padding: 0;
    margin: 0 0 10px
  }

  .main-timeline2 .timeline-content,
  .main-timeline2 .timeline:nth-child(2n) .timeline-content {
    padding: 0
  }

  .main-timeline2 .timeline-content:before,
  .main-timeline2 .timeline:nth-child(2n) .timeline-content:before {
    display: none
  }

  .main-timeline2 .timeline:nth-child(2n) .year,
  .main-timeline2 .year {
    position: relative;
    transform: translateY(0)
  }

  .main-timeline2 .timeline:nth-child(2n) .year:before,
  .main-timeline2 .year:before {
    border: none;
    border-right: 20px solid $primary-color;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    top: 50%;
    left: -23px;
    bottom: auto;
    right: auto;
    transform: rotate(0)
  }

  .main-timeline2 .timeline:nth-child(2n) .year:before {
    border-right-color: $primary-color
  }

  .main-timeline2 .timeline:nth-child(3n) .year:before {
    border-right-color: $primary-color
  }

  .main-timeline2 .timeline:nth-child(4n) .year:before {
    border-right-color: $primary-color
  }

  .main-timeline2 .inner-content {
    padding: 10px
  }
}
