.sidebar li .submenu {
  list-style: none;
  margin: 0;
  padding: 0;
  padding-left: 1rem;
  padding-right: 1rem;
}

.sidebar .nav-link {
  font-weight: 500;
  color: $light;
  ;
}

.sidebar .nav-link:hover,
.sidebar .nav-link:active,
.sidebar .nav-link:focus {
  color: $secondary-color;
}
