.contact-form-wrap {
  .form-group {
    margin-bottom: 20px;

    .form-control {
      height: 60px;
      border: 1px solid #eef2f6;
      box-shadow: none;
      width: 100%;
      background: #f4f9fc;
    }
  }

  .form-group-2 {
    margin-bottom: 13px;

    textarea {
      height: auto;
      border: 1px solid #eef2f6;
      box-shadow: none;
      background: #f4f9fc;
      width: 100%;
    }
  }
}

.social-icons {
  li {
    margin: 0 6px;
  }

  a {
    margin-right: 10px;
    font-size: 18px;
  }
}

.google-map {
  position: relative;
}

.google-map #map {
  width: 100%;
  height: 500px;
}

.mt-90 {
  margin-top: 90px;
}

.contact-block {
  text-align: center;
  border: 5px solid #eef2f6;
  padding: 50px 25px;

  i {
    font-size: 50px;
    margin-bottom: 15px;
    display: inline-block;
    color: $secondary-color;
  }
}

.contact_info {
  font: 400 16px/1.6 roboto, sans-serif;
  color: #6f8ba4;
  min-height: 77px;

}

.help-block ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
