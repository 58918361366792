/*=== MEDIA QUERY ===*/
@mixin mobile-xs {
  @media (max-width: 400px) {
    @content;
  }
}
@mixin mobile {
  @media (max-width: 480px) {
    @content;
  }
}
@mixin tablet {
  @media (max-width: 768px) {
    @content;
  }
}
@mixin desktop {
  @media (max-width: 992px) {
    @content;
  }
}
@mixin large-desktop {
  @media (max-width: 1200px) {
    @content;
  }
}
