/* Sidebar
================================================== */
.sidebar .widget-title {
  font-size: 16px;
  font-weight: 700;
  position: relative;
  margin: 0 0 30px;
  padding-left: 15px;
  text-transform: uppercase;
  border-left: 3px solid #ffb600;
}

/* Widget common */
.sidebar .widget {
  margin-bottom: 40px;
}

.sidebar-left .widget {
  margin-right: 20px;
}

.sidebar-right .widget {
  margin-left: 20px;
}

.sidebar .widget.box {
  padding: 25px;
}

.widget.box.solid {
  background: #f2f2f2;
}

.widget.box.red {
  background: #ec483b;
  color: #fff;
}

.widget.box.red .widget-title {
  color: #fff;
}

.widget ul li {
  line-height: 30px;
}

.sidebar .widget ul li a {
  color: #303030;
}

.sidebar .widget ul li a:hover {
  color: #ffb600;
}

.sidebar .widget ul li i {
  margin-right: 5px;
}

.sidebar .btn {
  font-weight: 700;
  font-size: 12px;
  margin-top: 15px;
  padding: 10px 25px;
}

/* Sidebar nav */
.sidebar ul.nav-tabs {
  border: 0;
}

.sidebar ul.nav-tabs li {
  width: 100%;
}

.sidebar ul.nav-tabs li a {
  color: #303030;
  border-radius: 0;
  padding: 15px 0;
  padding-left: 0;
  font-weight: 400;
  border-bottom: 1px solid #ddd;
  display: block;
  transition: 400ms;
}

.sidebar ul.nav-tabs li.active a,
.sidebar ul.nav-tabs li:hover a {
  color: #ffb600;
}

.sidebar ul.nav-tabs li {
  color: #303030;
  line-height: normal;
}

.sidebar ul.nav-tabs li:last-child a {
  border-bottom: 0;
}

/* Service menu */
.sidebar ul.service-menu li {
  width: 100%;
}

.sidebar ul.service-menu li a {
  background-color: #f4f4f4;
  padding: 15px 20px;
  border: 0;
  margin-bottom: 8px;
  position: relative;
  transition: 400ms;
  display: block;
}

.sidebar ul.service-menu li.active a,
.sidebar ul.service-menu li:hover a {
  background: #ffb600;
  color: #fff;
  cursor: pointer;
}

/* Recent News */
.widget.recent-posts .widget-title {
  margin-bottom: 35px;
}

.widget.recent-posts ul li {
  border-bottom: 1px solid #dadada;
  padding-bottom: 15px;
  margin-bottom: 17px;
}

.widget.recent-posts ul li:last-child {
  border: 0;
  padding-bottom: 0;
  margin-bottom: 0;
}

.widget.recent-posts .posts-thumb img {
  margin-right: 15px;
  width: 90px;
  height: 70px;
}

.widget.recent-posts .post-info .entry-title {
  font-size: 13px;
  font-weight: 600;
  line-height: 20px;
  margin: 0;
}

.widget.recent-posts .post-info .entry-title a {
  color: #303030;
  display: inline-block;
}

.widget.recent-posts .post-info .entry-title a:hover {
  color: #ffb600;
}

.widget.recent-posts .post-date {
  font-weight: 400;
  color: #999;
  text-transform: capitalize;
}

/* Widget tags */
.widget-tags ul > li {
  float: left;
  margin: 3px;
}

.sidebar .widget-tags ul > li a {
  border: 1px solid #dadada;
  color: #303030;
  display: block;
  font-size: 14px;
  padding: 3px 15px;
  transition: all 0.3s ease 0s;
}

.sidebar .widget-tags ul > li a:hover {
  background: #ffb600;
  color: #fff;
  border: 1px solid transparent;
}
