nav {
  background: $primary-color;
}

.nav-link {
  color: $light;

  &:hover,
  &:focus {
    color: $secondary-color;
  }
}

.active {
  color: $secondary-color;
}

ul.navbar-nav select.form-control {
  background-color: $primary-color;
  color: $light;
  font-size: 14px;
  border-color: $primary-color;
  width: 60px;
}

.dropdown-item {
  color: $primary-color;

  &:hover,
  &:focus {
    color: $secondary-color;
    background-color: $primary-color;
  }
}



/* ============ desktop view ============ */
@media all and (min-width: 992px) {
  .dropdown-menu li {
    position: relative;
  }

  .nav-item .submenu {
    display: none;
    position: absolute;
    left: 100%;
    top: -7px;
  }

  .nav-item .submenu-left {
    right: 100%;
    left: auto;
  }

  .dropdown-menu>li:hover {
    background-color: $primary-color
  }

  .dropdown-menu>li:hover>.submenu {
    display: block;
  }

  .sidebar li {
    position: relative;
  }

  .sidebar li .submenu {
    display: none;
    position: absolute;
    left: 100%;
    top: -7px;
    min-width: 240px;
  }

  .sidebar li:hover>.submenu {
    display: block;
  }
}

/* ============ desktop view .end// ============ */

/* ============ small devices ============ */
@media (max-width: 991px) {
  .dropdown-menu .dropdown-menu {
    margin-left: 0.7rem;
    margin-right: 0.7rem;
    margin-bottom: .5rem;
  }

  .sidebar .submenu,
  .sidebar .dropdown-menu {
    position: static !important;
    margin-left: 0.7rem;
    margin-right: 0.7rem;
    margin-bottom: .5rem;
  }
}
