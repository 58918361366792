/* Facts
================================================== */
.facts-wrapper {
  text-align: center;
}

.facts-wrapper .ts-facts {
  color: $light;
}

.ts-facts .ts-facts-icon i {
  font-size: 42px;
  color: $secondary-color;
}

.ts-facts .ts-facts-content .ts-facts-num {
  color: $light;
  font-size: 44px;
  margin: 30px 0 20px;
}

.ts-facts .ts-facts-content .ts-facts-title {
  font-size: 16px;
  color: $secondary-color;
  margin: 0;
}
