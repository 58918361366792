.hero-slider-area {
  padding: 2rem;
  background: $primary-grad;
  position: relative;
}

.main_slide {
  width: 500px;
  height: 400px;
}

.main_slide-img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.featurette {
  color: $light;
}

.brand-carousel {
  background: $light;
  display: flex;
  align-items: center;
  justify-content: center;
}

.single-logo {
  width: 200px;
  height: 150px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
  align-content: center
}

.brand-img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  flex: 1 0 auto;
  filter: grayscale(1);
}

.brand-img:hover {
  filter: grayscale(0);
}

@media (min-width: 768px) {
  .bd-placeholder-img-lg {
    font-size: 3.5rem;
  }
}
