/*
Theme Name: Medic
Author: Themefisher
Author URI: https://themefisher.com/
Description: Medicle Template
Version: 1.0.0

*/

// Fonts
@include font('exo2', normal, 400, '../fonts/Exo2-Regular.woff2');
@include font('exo2', normal, 600, '../fonts/Exo2-SemiBold.woff2');
@include font('exo2', normal, 700, '../fonts/Exo2-Bold.woff2');
@include font('exo2', normal, 900, '../fonts/Exo2-Black.woff2');
@include font('roboto', normal, 400, '../fonts/Roboto-Regular.woff2');
@include font('IcoFont', normal, 'Regular', '../fonts/icofont.woff2');
@include font('slick', normal, 'Regular', '../fonts/slick.woff2');
@include font('slick', normal, 'Regular', '../fonts/slick.woff');
@include font('slick', normal, 'Regular', '../fonts/slick.ttf');





body {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  font-family: $primary-font;
  font-size: calc(15px + 0.390625vw);
  font-weight: 400;
  line-height: 1.6;
  color: $primary-color;
  -webkit-font-smoothing: antialiased;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  font-family: $primary-font;
  font-weight: 700;
}

h1,
.h1 {
  font-size: 2.5rem;
}

h2,
.h2 {
  font-size: 2rem;
}

h3,
.h3 {
  font-size: 1.5rem;
}

h4,
.h4 {
  font-size: 1.3rem;
  line-height: 30px;
}

h5,
.h5 {
  font-size: 1.25rem;
}

h6,
.h6 {
  font-size: 1rem;
}

p {
  line-height: 30px;
}

@include mobile {
  .logo {
    font-size: 1.5em;
  }
}

.border-bottom {
  color: $primary-color;
}

.facebook {
  color: $light;

  :hover {
    color: #3b5998;
  }
}

.twitter {
  color: $light;

  :hover {
    color: #1da1f2;
  }
}

.telegram {
  color: $light;

  :hover {
    color: #0088cc;
  }
}

.youtube {
  color: $light;

  :hover {
    color: #ff0000;
    ;
  }
}

.instagram {
  color: $light;

  :hover {
    color: #e1306c;
    ;
  }
}

.linkedin {
  color: $light;

  :hover {
    color: #00a0dc;
    ;
  }
}


.top-header {

  &--text-color:hover,
  &--text-color:focus {
    color: $light;
  }

  &--bg-color {
    background-color: $secondary-color;
  }
}
