.navbar-toggle .icon-bar {
  background: $primary-color;
}

input[type='email'],
input[type='password'],
input[type='text'],
input[type='tel'] {
  box-shadow: none;
  height: 45px;
  outline: none;
  font-size: 14px;

  &:focus {
    box-shadow: none;
    border: 1px solid $primary-color;
  }
}

.form-control {
  box-shadow: none;
  border-radius: 0;

  &:focus {
    box-shadow: none;
    border: 1px solid $primary-color;
  }
}

//

.py-7 {
  padding: 7rem 0px;
}

// Button Style

.btn {
  display: inline-block;
  font-size: 14px;
  font-size: 0.8125rem;
  font-weight: 700;
  letter-spacing: 0.5px;
  padding: 0.75rem 2rem;
  font-family: $primary-font;
  text-transform: uppercase;
  border-radius: 5px;
  border: 2px solid transparent;
  box-shadow: 0 11px 22px rgba(34, 34, 34, 0.5);
  transition: all 0.35s ease;

  &.btn-icon {
    i {
      border-left: 1px solid rgba(255, 255, 255, 0.09);
      padding-left: 15px;
    }
  }

  &:focus {
    outline: 0px;
    box-shadow: none;
  }
}

.btn-main {
  background: $primary-color;
  color: $light;
  border-color: $primary-color;

  &:hover {
    background: $secondary-color;
    border-color: $secondary-color;
    color: $primary-color;
  }
}

.btn-main-2 {
  background: $secondary-color;
  color: $primary-color;
  border-color: $secondary-color;

  &:hover {
    background: $primary-color;
    color: $secondary-color;
    border-color: $primary-color;
  }
}

.btn-solid-border {
  border: 2px solid $primary-color;
  background: transparent;
  color: $primary-color;

  &:hover {
    border: 2px solid $primary-color;
    color: $light;
    background: $primary-color;

    &.btn-icon {
      i {
        border-left: 1px solid rgba(255, 255, 255, 0.09);
      }
    }
  }

  &.btn-icon {
    i {
      border-left: 1px solid rgba(0, 0, 0, 0.09);
    }
  }
}

.btn-transparent {
  background: transparent;
  color: $black;
  border-color: $primary-color;

  &:hover {
    background: $primary-color;
    color: $light;
  }
}

.btn-white {
  background: $light;
  border-color: $light;
  color: $black;

  &:hover {
    background: $primary-color;
    color: $light;
    border-color: $primary-color;
  }
}

.btn-solid-white {
  border-color: $light;
  color: $light;

  &:hover {
    background: $light;
    color: $black;
  }
}

.btn-round {
  border-radius: 4px;
}

.btn-round-full {
  border-radius: 50px;
}

.btn.active:focus,
.btn:active:focus,
.btn:focus {
  outline: 0;
}

// Background

.bg-gray {
  background: $gray;
}

.bg-primary {
  background: $primary-color;
}

.bg-primary-dark {
  background: darken($primary-color, 10%);
}

.bg-primary-darker {
  background: darken($primary-color, 20%);
}

.bg-dark {
  background: $black;
}

.bg-gradient {
  background-image: linear-gradient(145deg,
      rgba(19, 177, 205, 0.95) 0%,
      rgba(152, 119, 234, 0.95) 100%);
  background-repeat: repeat-x;
}

//  Section Title
.section {
  padding: 50px 0;
}

.section-sm {
  padding: 70px 0;
}

.section-bottom {
  padding-bottom: 100px;
}

.subtitle {
  color: $primary-color;
  font-size: 14px;
  letter-spacing: 1px;
}

.overlay {
  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    opacity: 0.9;
    background: $primary-color;
  }
}

.overly-2 {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
  }
}

.text-sm {
  font-size: 14px;
}

.text-md {
  font-size: 2rem;
}

.text-lg {
  font-size: 3rem;
}

.no-spacing {
  letter-spacing: 0px;
}

/* Links */
a {
  color: $link-color;
  text-decoration: none;
  -webkit-transition: all 0.35s ease;
  -moz-transition: all 0.35s ease;
  -ms-transition: all 0.35s ease;
  -o-transition: all 0.35s ease;
  transition: all 0.35s ease;
}

a:focus,
a:hover {
  color: $hover-link-color;
  text-decoration: none;
}

a:focus {
  outline: none;
}

.content-title {
  font-size: 40px;
  line-height: 50px;
}

.card-text {
  line-height: 24px;
}

.page-title {
  padding: 120px 0px 70px 0px;
  position: relative;

  .block {
    h1 {
      color: $light;
    }

    p {
      color: $light;
    }
  }

  .breadcumb-nav {
    margin-top: 60px;
    padding-top: 30px;
    border-top: 1px solid rgba(255, 255, 255, 0.06);
  }
}

.slick-slide {

  &:focus,
  a {
    outline: none;
  }
}

@include mobile {

  h2,
  .h2 {
    font-size: 1.3rem;
    line-height: 36px;
  }
}

.title-color {
  color: $primary-color;
}

.secondary-color {
  color: $secondary-color;
}

.secondary-bg {
  background: $secondary-color;
}

.section-title {
  margin-bottom: 70px;

  h2 {
    color: $title-color;
  }
}

.gray-bg {
  background: #f4f9fc;
}

@include mobile {
  .text-lg {
    font-size: 28px;
  }
}

@include mobile-xs {
  .text-lg {
    font-size: 28px;
  }
}

.dark-bg {
  background: $primary-color;
  color: $light;
}

hr.yellow {
  border-top: 4px solid $secondary-color;
}

blockquote {
  p {
    padding-left: 20px;
    border-left: 4px solid $secondary-color;
  }
}


.page-item.active {
  .page-link {
    background-color: $primary-color  !important;
    border-color: $primary-color  !important;
  }
}

.page-link {
  color: $primary-color;
}

.page-link:hover {
  background: $secondary-color  !important;
}

.goUp {
  display: none;
  position: fixed;
  bottom: 20px;
  right: 30px;
  z-index: 99;
  font-size: 18px;
  border: none;
  outline: none;
  background-color: $secondary-color;
  color: $light;
  cursor: pointer;
  padding: 15px;
  border-radius: 50%;

  &:hover {
    background-color: $primary-color;
  }

  i {
    color: $light;
  }

  @media (max-width: 575px) {
    padding: 7px;
    font-size: 16px;
  }
}


.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  background: $secondary-color;
}


.iti__flag {
  background-image: url("../images/flag/flags.png");
}

@media (-webkit-min-device-pixel-ratio: 2),
(min-resolution: 192dpi) {
  .iti__flag {
    background-image: url("../images/flag/flags@2x.png");
  }
}

.img-wrap {
  position: relative;
  text-align: center;
  overflow: hidden;
  display: block
}

.img-wrap img {
  -o-object-fit: cover;
  object-fit: cover
}

.gallery-wrap .img-big-wrap {
  display: block;
  text-align: center;
  width: 100%;
  height: 560px;
  margin-bottom: 1rem;
  overflow: hidden;
  background-color: #fff
}

.gallery-wrap .img-big-wrap a {
  text-align: center;
  display: block;
  height: 100%
}

.gallery-wrap .img-big-wrap img {
  max-width: 100%;
  max-height: 100%;
  height: auto;
  cursor: zoom-in
}

.gallery-wrap .thumbs-wrap {
  text-align: center
}

.gallery-wrap .thumbs-wrap .item-thumb {
  width: 60px;
  height: 60px;
  border: 1px solid #dee2e6;
  border-radius: .35rem;
  margin: 3px;
  display: inline-block;
  overflow: hidden
}

.gallery-wrap .thumbs-wrap a.item-thumb:hover {
  border-color: #9da1a7
}

.gallery-wrap .thumbs-wrap img {
  max-width: 100%;
  max-height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  cursor: zoom-in
}




