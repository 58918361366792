#service .item-serv {
  background: #fff;




  box-shadow: 0 0 0 1px #f2f2f2;
  border-radius: 20px;
  transition: all 0.5s ease-out;
  transform: scale(1);
}

#service .item-serv:hover .img-part,
#service .item-serv:active .img-part {
  color: $secondary-color;
  animation: mover 1s infinite alternate;
}

@-webkit-keyframes mover {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  100% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
}

@keyframes mover {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  100% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
}
