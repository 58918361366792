/*Preloader Area Style*/
.preloader {
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: $primary-color;
  top: 0;
  left: 0;
  z-index: 99999;
}

.preloader .lds-ripple {
  position: relative;
  width: 80px;
  height: 80px;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.preloader .lds-ripple div {
  position: absolute;
  border: 2px solid $secondary-color;
  opacity: 1;
  border-radius: 0;
  -webkit-animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.preloader .lds-ripple div:nth-child(2) {
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s;
}

@-webkit-keyframes lds-ripple {
  0% {
    top: 28px;
    left: 28px;
    width: 0;
    height: 0;
    opacity: 1;
  }

  100% {
    top: -1px;
    left: -1px;
    width: 58px;
    height: 58px;
    opacity: 0;
  }
}

@keyframes lds-ripple {
  0% {
    top: 28px;
    left: 28px;
    width: 0;
    height: 0;
    opacity: 1;
  }

  100% {
    top: -1px;
    left: -1px;
    width: 58px;
    height: 58px;
    opacity: 0;
  }
}
